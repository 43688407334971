import { Home, About } from '../containers';

export const routes = [
  {
    title: 'Dashboard',
    path: '/',
    component: <Home />,
    protected: true,
  },
  {
    title: 'WorkSlug',
    path: '/:workSlug',
    component: <Home />,
    protected: true,
  },
  {
    title: 'WorkSlug',
    path: '/about',
    component: <About />,
    protected: true,
  },
];
