import style from './about.module.scss';
import { Header } from '../../components';
import useWindowDimensions from '../../helpers/windowWidth';
import { WorkBar, AboutBar } from '../../components';

const About = () => {
  const { width } = useWindowDimensions();
  return (
    <>
      <Header about mobile={width < 481} />
      {width < 481 ? null : (
        <>
          <WorkBar />
          <AboutBar />
        </>
      )}
      <div className={style.about}>
        <div className={style.wrapper}>
          <div className={style.column}>
            <div className={style.section}>
              <h2>EREN SU KIBELE YARMAN</h2>
              <p>
                Born in 1989, she holds an MFA from School of Visual Arts’
                Design (2015) and a BFA from Bilkent University’s Communication
                Design (2012) with a concentration of film studies. She is
                currently a doctoral student in Middle East Technical
                University's Settlement Archaeology Department. (2019-...)
              </p>
              <p>
                Kibele has a design practice that visualizes information and
                emotion for the arts and culture field including clients from
                museums, galleries, publishing houses, publications and beyond
                in addition to collage work.
              </p>
              <p>
                Please{' '}
                <a href="mailto:eskyarman@gmail.com" target={'_blank'}>
                  write to her
                </a>{' '}
                for inquiries about commissioned work.
              </p>
              <p>
                You can also see most of her latest work{' '}
                <a
                  href="https://www.instagram.com/kibeleyarman/"
                  target={'_blank'}
                >
                  on Instagram.
                </a>
              </p>
            </div>
            <div className={style.section}>
              <h2>OTHER EDUCATION:</h2>
              <p>
                'Light to Ink: Short Course on Printmaking'
                <br />
                Rhode Island School of Design
                <br />
                (07/2014 - 08/2014)
              </p>
              <p>
                Graphics, Short Program <br />
                Central Saint Martins
                <br />
                (01/08/2011 - 12/08/2011)
              </p>
              <p>
                Graphic Design Intensive Short Program
                <br />
                Parsons the New School for Design
                <br />
                (07/2010 - 08/2010)
              </p>
            </div>
            <div className={style.section}>
              <h2>SELECT GROUP EXHIBITIONS</h2>
              <p>
                Bak bana - Look At Me, Augsburg University of <br />
                Applied Sciences, Faculty of Design, Augsburg, <br />
                Germany (11/2017)
              </p>
              <p>
                A Human World Map, Wisedog, Larissa, Greece <br />
                (02/2017)
              </p>
              <p>
                Emprise New Design Ventures, SVA Chelsea <br />
                Gallery, New York (05/2015)
              </p>
              <p>
                Obsoletely Fabulous, SVA Gramercy Gallery, New <br />
                York (05/2014)
              </p>
              <p>
                Livin’ It, Art Directors Club Gallery, New York <br />
                (04/2014)
              </p>
            </div>
            <div className={style.section}>
              <h2>CONDUCTED WORKSHOPS:</h2>
              <p>
                'Doğrusal Zamanda Yer Almayan Bir Kutlama: <br />
                Analog Kolaj Atölyesi', 'Mâziyi Korumak: Sadberk
                <br />
                Hanım Müzesi'nden bir Seçki' sergisi için, Meşher, <br />
                Vehbi Koç Vakfı (08/05/2021)
              </p>
              <p>
                'Visualizing Emotion: An Analogue Collage <br />
                Workshop'
                <br />
                Studio-X Istanbul (02/2018),
              </p>
              <p>
                'Moving Pieces: An Analogue Stop-Motion
                <br />
                Workshop' Bilgi University, Department of
                <br />
                Architecture (12/2020)
              </p>
              <p>
                'Visualizing Emotion: An Analogue Collage
                <br />
                Workshop'
                <br />
                Studio-X Istanbul (02/2018),
              </p>
              <p>
                MEF University Architecture Department
                <br />
                (11/2017),
              </p>
              <p>
                Kadir Has University Visual Communication
                <br />
                Department (11/2017).
              </p>
              <p>
                'Letter as Object'
                <br />
                Basaksehir Living Lab (09/2016),
                <br />
                Studio-X Istanbul with Manifold (07/2016).
              </p>
            </div>
          </div>
          <div className={style.column}>
            <div className={style.section}>
              <h2>AWARDS:</h2>
              <p>
                GMK [Turkish Graphic Designers Association] <br />
                2021, Best Book Design Award
              </p>
              <p>
                GMK [Turkish Graphic Designers Association] <br />
                2021, TÜYAP Best Book Cover Award
              </p>
              <p>
                GMK [Turkish Graphic Designers Association] <br />
                2021, Excellence in Book Design Award
              </p>
              <p>
                GMK [Turkish Graphic Designers Association] <br />
                2021, Excellence in Book Cover Design Award
              </p>
              <p>
                GMK [Turkish Graphic Designers Association] <br />
                2020, Ihap Hulusi Poster Design Award
              </p>
              <p>
                GMK [Turkish Graphic Designers Association] <br />
                Poster, Merit Award
              </p>
              <p>
                AIGA and Design Observer, 50 Books 50 Covers <br />
                2018, Book Cover
              </p>
              <p>
                European Design Awards, 2018, Book Cover, <br />
                Bronze
              </p>
              <p>
                European Design Awards, 2018, Poster Series,
                <br />
                Bronze
              </p>
              <p>
                Graphis Poster Annual, 2018, Poster Series, <br />
                Honorable Mention
              </p>
              <p>
                Graphis Poster Annual, 2018, Poster, ‘Honorable <br />
                Mention’
              </p>
              <p>
                Graphis Design Annual 2017, Brand Identity <br />
                Design, Merit Award
              </p>
              <p>
                Graphis Design Annual 2017, Logo Design, Merit <br />
                Award
              </p>
              <p>
                GMK [Turkish Graphic Designers Association] 2017, <br />
                Flyer Design, Merit Award
              </p>
              <p>
                GMK [Turkish Graphic Designers Association] 2017, <br />
                Brand Identity, Merit Award
              </p>
              <p>
                SVA After School Special 2016: Film & Animation <br />
                Festival, Feature
              </p>
            </div>

            <div className={style.section}>
              <h2>WRITINGS ABOUT DESIGN (IN TURKISH):</h2>
              <p>
                'Tasarım Üzerine Skeptik Saltolar: Kimlik + <br />
                Okunabilirlik', <br />
                Manifold{' '}
                <a
                  target={'_blank'}
                  href="https://www.manifold.press/kimlik-okunabilirlik"
                >
                  Read Here
                </a>
              </p>
              <p>
                'Tasarım Üzerine Skeptik Saltolar: Sosyal Tasarım <br />
                + Müsteri', <br />
                Manifold{' '}
                <a
                  target={'_blank'}
                  href="https://www.manifold.press/sosyal-tasarim-arti-musteri"
                >
                  Read Here
                </a>
              </p>
              <p>
                'Tasarım Üzerine Skeptik Saltolar: Soyutlama + <br />
                Etik', <br />
                Manifold{' '}
                <a
                  target={'_blank'}
                  href="https://www.manifold.press/soyutlama-etik"
                >
                  Read Here
                </a>
              </p>
              <p>
                'Form, Fonksiyon, Güzellik ve Büyü', <br />
                Manifold{' '}
                <a
                  target={'_blank'}
                  href="https://www.manifold.press/form-fonksiyon-guzellik-ve-buyu"
                >
                  Read Here
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
      {width < 481 ? (
        <div className={style.socialMediaWrapper}>
          <a href="https://www.instagram.com/kibeleyarman/" target={'_blank'}>
            INSTAGRAM
          </a>
          <a href="mailto:eskyarman@gmail.com" target={'_blank'}>
            CONTACT
          </a>
          <a
            href="https://workingnotworking.com/36913-eren-su-kibele"
            target={'_blank'}
          >
            WORKING NOT WORKING
          </a>
        </div>
      ) : null}
    </>
  );
};

export default About;
